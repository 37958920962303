import '../../assets/css/tucana_provides.less';
import img_amms from '../../assets/image/img_amms@2x.png';
import img_launchpad from '../../assets/image/img_launchpad@2x.png';
import img_staking from '../../assets/image/img_liquid@2x.png';
import img_orderbook from '../../assets/image/img_orderbook@2x.png';
import img_perp from '../../assets/image/img_perpetuals@2x.png';
import FadeInUpWhenVisible from '../animation/FadeInUpWhenVisible';
const topList = [
  {
    wrapClass: 'bg_amms',
    imgUrl: img_amms,
    title: 'Multi-Algo AMMs',
    desc: 'Aggregate liquidity across multiple AMMs driven by different algos such as CLMM, Curve style, etc. Maximize liquidity utilization and deliver the best prices to users at any time.'
  },
  {
    wrapClass: 'bg_orderbook',
    imgUrl: img_orderbook,
    title: 'Orderbook',
    desc: 'Allow for limit order, DCA and a variety of advanced trading modes to embrace the highest flexibility in crypto trading.'
  },
  {
    wrapClass: 'bg_staking',
    imgUrl: img_staking,
    title: 'Liquid Staking',
    desc: 'Easily participate in the liquid staking of multiple chains on Tucana with just one click. '
}]

const bottomList = [
  {
    wrapClass: 'bg_perpetuals',
    imgUrl: img_perp,
    title: 'Perpetuals',  
    desc: 'Earn lucrative on-chain yields by providing liquidity or trade with up to 100x leverage to seize every single market opportunity.'
  },
  {
    wrapClass: 'bg_launchpad',
    imgUrl: img_launchpad,
    title: 'Launchpad',
    desc: 'A completely on-chain venue for asset launching within the IBC ecosystem. Support multiple mainstream asset launching modes.'
  }
]
function TucanaProvides() {
  return (
    <div className="tucana_provides">
        <div className="center">
        <FadeInUpWhenVisible>
          <div className="part_title pc_title">
            Tucana provides a powerful and<br/> fast DeFi infrastructure<br/> for all assets connected to IBC.
          </div>
          <div className="part_title h5_title">
            Tucana provides a powerful and fast DeFi infrastructure for all assets connected to IBC.
          </div>
          </FadeInUpWhenVisible>
          <div className="content">
          <FadeInUpWhenVisible>
            <div className="rowList">
                {
                  topList.map(item=>(
                    <Item  key={item.title} {...item}  />
                  ))
                }
              </div>
              </FadeInUpWhenVisible>
              <FadeInUpWhenVisible>
            <div className="rowList rowBottomList">
                {
                  bottomList.map(item=>(
                    <Item  key={item.title} {...item}/>
                  ))
                }
            </div>
            </FadeInUpWhenVisible>
          </div>
        </div>
      </div>
  );
}

export default TucanaProvides;

const Item = ({wrapClass, imgUrl, title, desc}: any) => {
  return (
      <div className={`item ${wrapClass}`}>
        <img src={imgUrl} alt="" />
      <div className="text">
        <div className="tit">{title}</div>
        <div className="desc">{desc}</div>
      </div>
    </div>
  )
}
