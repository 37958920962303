import { useState } from 'react';
import '../../assets/css/footer.less';
import icon_copy from '../../assets/new-image/icon_copy@2x.png';
import icon_discord from '../../assets/new-image/icon_discord@2x.png';
import icon_discord_hov from '../../assets/new-image/icon_discord_hov@2x.png';
import icon_download from '../../assets/new-image/icon_download@2x.png';
import icon_medium from '../../assets/new-image/icon_medium@2x.png';
import icon_medium_hov from '../../assets/new-image/icon_medium_hov@2x.png';
import icon_twitter from '../../assets/new-image/icon_twitter@2x.png';
import icon_twitter_hov from '../../assets/new-image/icon_twitter_hov@2x.png';
import pc_logo from '../../assets/new-image/logo_night@2x.png';
import FadeInUpWhenVisible from '../animation/FadeInUpWhenVisible';
const Community = () => {
  const community:any =  [
    {
      label:'X(Twitter)',
      icon:icon_twitter,
      icon_hov:icon_twitter_hov,
      link:'https://twitter.com/TucanaNetwork'
    },
    {
      label:'Discord',
      icon:icon_discord,
      icon_hov:icon_discord_hov,
      link:'https://discord.gg/tucana'
    },
    {
      label:'Medium',
      icon:icon_medium,
      icon_hov:icon_medium_hov,
      link:'https://tucananetwork.medium.com'
    }
  ]
  return (
    <div className="community">
    {
      community.map((ele:any,index:any)=>{
        return (
          <div className="community-item" key={ele.label+`${index}`}>
            <a key={ele?.label} href={ele?.link==''?'#':ele?.link} target={ele?.link==''?'_self':'_blank'}>
              <img
                className="icon default"
                src={ele?.icon}
                alt=""
              />
              <img
                className="icon hover"
                src={ele?.icon_hov}
                alt=""
              />
              </a>
          </div>
        )
      })
    }
  </div>
  )
}

function Footer() {
	const list = [
		{
			title:'User terms',
			content:[
				{
					label:'Privacy Policy',
					link:''
				},
				{
					label:'Terms and Conditions',
					link:''
				}
			]
		},
		{
			title:'Application',
			content:[
				{
					label:'Trade',
					link:'https://birdee.ag.tucana.zone'
				}
			]
		},
		{
			title:'Network',
			content:[
				{
					label:'Bridge',
					link:''
				},
				{
					label:'Scan',
					link:'https://scan.testnet.initia.xyz/birdee-1'
				},
				,
				{
					label:'Faucet',
					link:'https://birdee.faucet.tucana.zone'
				}
			]
		},
		// {
		// 	title:'Community',
		// 	content:[
		// 		{
		// 			label:'X(Twitter)',
		// 			link:'https://twitter.com/TucanaNetwork'
		// 		},
		// 		{
		// 			label:'Discord',
		// 			link:'https://discord.gg/tucana'
		// 		},
		// 		{
		// 			label:'Medium',
		// 			link:'https://tucananetwork.medium.com'
		// 		}
		// 	]
		// },
		{
			title:'Documents',
			content:[
				{
					label:'Product Docs',
					link:'https://tucana-1.gitbook.io/tucana-product-docs'
				},
				{
					label:'Development Docs',
					link:''
				}
			]
		},
		{
			title:'Team',
			content:[
				{
					label:'Contact us',
					link:''
				},
				{
					label:'Media Kit',
					link:''
				}
			]
		}
	]

  const [isCopied,setIsCopied] = useState(false)

  const copy=(text:any)=>{
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
    } else {
      const inp = document.createElement("input")
      inp.readOnly = true
      document.body.appendChild(inp)
      inp.value = text
      inp.select()
      document.execCommand("Copy")
      document.body.removeChild(inp)
    }
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
  }


  return (
		<div className="footer_block">
      <FadeInUpWhenVisible>
        <div className="center">
          <div className="top">
            <img
              className="pc_logo"
              src={pc_logo}
              alt=""
            />
           <Community />
          </div>
          <div className="socical">
            {
              list.map((item,index)=>{
                return (
                  <div key={item.title} className="item">
                    <div className="tit">{item.title}</div>
                    {
                      item.content.map((ele:any,index)=>{
                        return (
                          <div className="link-item" key={ele.label} onClick={(e)=>{
                            if(['Contact us','Media Kit'].includes(ele.label)) {
                              e.preventDefault()
                              if(ele.label=='Contact us'&&!isCopied) {
                                copy('hello@tucana.zone')            
                              }
                              if(ele.label=='Media Kit') {
                                window.open('https://tucana-testnet.netlify.app/public/Tucana_kit.zip', '_self')
                              }
                            }
                          }}>
                            <a 
                              key={ele?.label} 
                              href={ele?.link==''?'#':ele?.link} 
                              target={ele?.link==''?'_self':'_blank'} 
                              onMouseLeave={()=>setIsCopied(false)}
                     
                            >
                              {ele?.label}
                            </a>
                            {ele.label=='Contact us'&&!isCopied&&
                              <div className="contact" onClick={()=>copy('hello@tucana.zone')}>
                                <p>hello@tucana.zone</p>
                                <img src={icon_copy} alt="" />
                              </div>
                            }
                            {ele.label=='Contact us'&&isCopied&&
                              <div className="contact">
                                <p>Copied</p>
                              </div>
                            }
                            {ele.label=='Media Kit'&&
                              <a href='https://tucana-testnet.netlify.app/public/Tucana_kit.zip' className="contact">
                                <img className='down_img' src={icon_download} alt="" />
                                <p>Download</p>
                              </a>
                            }
                            {/* {ele.label=='Development Docs'&&
                              <div className="contact">
                                <p>Coming Soon</p>
                              </div>
                            } */}
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            }
          </div>
          <p className="copyright">© Copyright 2024 Tucana. All Rights Reserved.</p>
        </div>
      </FadeInUpWhenVisible>
	</div>
  );
}

export default Footer;
