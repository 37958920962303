import '../../assets/css/tucana_chain.less';
import icon_antimev from '../../assets/image/icon_antimev@2x.png';
import icon_highperformance from '../../assets/image/icon_highperformance@2x.png';
import icon_minimalgas from '../../assets/image/icon_minimalgas@2x.png';
import icon_transaction from '../../assets/image/icon_transaction@2x.png';
import img_bornfortrading from '../../assets/image/img_bornfortrading.png';
import FadeInUpWhenVisible from '../animation/FadeInUpWhenVisible';
function TucanaChain() {
  const chainList=[
    {
      label:'High Performance',
      icon:icon_highperformance
    },
    {
      label:'Minimal Gas',
      icon:icon_minimalgas
    },
    {
      label:'Anti-MEV',
      icon:icon_antimev
    },
    {
      label:'Transaction Classification',
      icon:icon_transaction
    }
  ]
  return (
    <div className="tucana_chain">
      <FadeInUpWhenVisible>
        <div className="center">
          <div className="part_title pc_tit">
            Born for Trading , Earn and Derivatives
          </div>
          <div className="part_title h5_tit">
            Born for Trading ,<br /> Earn and Derivatives
          </div>
          <div className="label_icon">
            {
              chainList.map((item,index)=>{
                return (
                  <div className="item" key={item.label}>
                    <img
                      className="icon"
                      src={item.icon}
                      alt=""
                    />
                    <div className="label">{item.label}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </FadeInUpWhenVisible>
      <FadeInUpWhenVisible>
        <div className="animation_box">
          <img src={img_bornfortrading} alt="" />
          <video   
            muted
            playsInline
            loop
            autoPlay
            data-wf-ignore="true"
            data-object-fit="cover">
              <source
                src="/BornforTrading.mp4"
                type="video/mp4"
              />
          </video>
        </div>
        {/* <div className="animation_box h5_animation_box">
          <img src={h5_chain} alt="" />
          <video   
            muted
            playsInline
            loop
            autoPlay
            data-wf-ignore="true"
            data-object-fit="cover">
              <source
                src="/H5_BornforTrading.mp4"
                type="video/mp4"
              />
          </video> 
        </div> */}
      </FadeInUpWhenVisible>
    </div>
  );
}

export default TucanaChain;
