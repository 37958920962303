import '../../assets/css/tucana_build.less';
import icon_derivatives from '../../assets/image/icon_derivatives@2x.png';
import icon_predictionmarket from '../../assets/image/icon_predictionmarket@2x.png';
import icon_trading from '../../assets/image/icon_trading@2x.png';
import icon_options from '../../assets/image/icon_vault@2x.png';
import img_buildtucanaecosystem from '../../assets/image/img_buildtucanaecosystem.png';
import FadeInUpWhenVisible from '../animation/FadeInUpWhenVisible';
import Button from './Button';
function TucanaBuild() {
  const buildList = [
    {
      label:'Prediction Market',
      icon:icon_predictionmarket
    },
    {
      label:'Vault',
      icon:icon_options
    },
    {
      label:'Margin Trading',
      icon:icon_trading
    },
    {
      label:'Other Derivatives',
      icon:icon_derivatives
    }
  ]
  // useEffect(() => {
  //   const images_build = document.querySelectorAll('.image_animation_build')
  //   images_build.forEach((image, index) => {
  //     if (index<2) {
  //       setTimeout(() => {
  //         image.classList.add('show')
  //       }, (index+1) * 500)
  //     }else{
  //       setTimeout(() => {
  //         image.classList.add('show-and-float')
  //       }, (index) * 200)
  //     }
  //   })
  //   timer.current = setInterval(() => {
  //     images_build.forEach(image => {
  //       image.classList.remove('show')
  //       image.classList.remove('show-and-float')
  //     })
  //     setTimeout(() => {
  //       images_build.forEach((image, index) => {
  //         if (index<2) {
  //           setTimeout(() => {
  //             image.classList.add('show')
  //           }, (index) * 500)
  //         }else{
  //           setTimeout(() => {
  //             image.classList.add('show-and-float')
  //           }, (index-1) * 200)
  //         }
  //       })
  //     }, 100)
  //   }, 6000)


  //   return () => {
  //       clearInterval(timer.current)
  //       timer.current = null
  //   }
  // }, [])
  return (
    <div className="tucana_build">
      <div className="center">
      <FadeInUpWhenVisible>
        <div className="part_title">Build Tucana Ecosystem</div>
        </FadeInUpWhenVisible>
        <FadeInUpWhenVisible>
        <div className="desc">Build High End Trading Products With Out-Of-The-Box Defi Modules of Tucana</div>
        </FadeInUpWhenVisible>
        <FadeInUpWhenVisible>
        <div className="btn">
          <Button text=''/>
        </div>
        </FadeInUpWhenVisible>
        <div className="list_animation">
          <div className="list">
            {buildList.map((item,index)=>{
              return (
                <div className="item" key={item.label}>
                  <div className='item-content'>
                  <FadeInUpWhenVisible>
                  <img src={item.icon} alt="" />
                  </FadeInUpWhenVisible>
                  <FadeInUpWhenVisible>
                    <p>{item.label}</p>
                  </FadeInUpWhenVisible>
                  </div>
                </div>
              )
            })}
          </div>
          <FadeInUpWhenVisible>
          <div className="animation">
          <img src={img_buildtucanaecosystem} alt="" />
          <video   
            muted
            playsInline
            loop
            autoPlay
            data-wf-ignore="true"
            data-object-fit="cover">
              <source
                src="/build_video.mp4"
                type="video/mp4"
              />
          </video>
          </div>
          </FadeInUpWhenVisible>
        </div>
      </div>
  </div>
  );
}

export default TucanaBuild;
