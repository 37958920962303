import './assets/css/index.less';
import Banner from './components/common/Banner';
import Footer from './components/common/Footer';
import TucanaBuild from './components/common/TucanaBuild';
import TucanaChain from './components/common/TucanaChain';
import TucanaPotv from './components/common/TucanaPotv';
import TucanaProvides from './components/common/TucanaProvides';

function App() {
  return (
    <div className="home_container">
      <Banner />
      <TucanaChain />
      <TucanaProvides />
      <TucanaPotv />
      <TucanaBuild />
      <Footer />
    </div>
  );
}

export default App;
