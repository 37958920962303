import '../../assets/css/tucana_potv.less';
import img_augmented from '../../assets/image/img_augmented@2x.png';
import img_robust from '../../assets/image/img_robust@2x.png';
import img_superior from '../../assets/image/img_superior@2x.png';
import FadeInUpWhenVisible from '../animation/FadeInUpWhenVisible';

const list = [
  {
    wrapClass: 'img_potv1',
    title: 'Superior capital utilization',
    imgClass: 'img_superior',
    content: 'Maximize your capital efficiency and earn multiple layers of on-chain yields at one stop',
    img: img_superior
  },
  {
    wrapClass: 'img_potv2',
    title: 'Augmented security',
    imgClass: 'img_augmented',
    content: 'Consolidate the chain security by introducing a variety of mainstream assets',
    img: img_augmented
  },
  {
    wrapClass: 'img_potv3',
    title: 'Robust underlying liquidity ',
    imgClass: 'img_robust',
    content: 'Stimulate the liquidity boostrap as the consensus process moves forward continually ',
    img: img_robust
}
]
function TucanaPotv() {
  return (
    <div className="tucana_potv">
      <div className="video_box">
        <video   
          muted
          playsInline
          loop
          autoPlay
          data-wf-ignore="true"
          data-object-fit="cover"
        >
            <source
              src="/waveform.webm"
              type="video/webm"
            />
        </video>
      </div>
    <div className="center">
    <FadeInUpWhenVisible>
        <div className="part_title tucana_potv_tit">Proof of Tradable Value</div>
        </FadeInUpWhenVisible>
    </div>
    <div className="center">
      <div className="tucana_potv_list">
        {
          list.map(item=>(<Item key={item.title} {...item} />))
        }
      </div>
    </div>
  </div>
  );
}

export default TucanaPotv;

const Item = ({wrapClass, img, imgClass, title, content}: any) => {
  return (
    <div className={`item ${wrapClass}`} >
      <FadeInUpWhenVisible>
        <p>{title}</p>
        <span>{content}</span>
        <img className={imgClass} src={img} alt="" />
      </FadeInUpWhenVisible>
  </div>
  )
}
